import React, { useState, useRef, useEffect } from 'react';
import { Image, Badge, Spinner, Row, Tooltip } from 'react-bootstrap';
import ReactTimeAgo from 'react-time-ago';
import guardianDefaultProfilePicture from '../../assets/profile/default-profile-guardian.svg';
import dependentDefaultProfilePicture from '../../assets/profile/default-profile-pic.svg';
import partnerDefaultProfilePicture from '../../assets/profile/default-profile-uni.svg';
import iconEdit from '../../assets/icon/icon-edit.png';
import starIcon from '../../assets/icon/icon-my-room-star.svg';
import flagIcon from '../../assets/icon/icon-my-room-flag.svg';
import binkyIcon from '../../assets/images/icon-binky-selected.svg';
import verfiedIcon from '../../assets/icon/Verified-badge.svg';
import unVerfiedIcon from '../../assets/icon/Unverified.svg';
import unverifiedPatch from '../../assets/icon/Bell_ic.svg';
import { HiDotsHorizontal } from '@react-icons/all-files/hi/HiDotsHorizontal';
import { useAuth } from '../auth/Auth';
import MyBadge from './MyBadge';
import { isStrNullOrEmpty, isDependent } from '../../utils/utils';
import {
  capitalizeFirstLetter,
  getDefaultProfileImage,
  getUserBannerUrl,
  getUserProfileUrl,
  guardianNameCorrection,
} from '../../utils/camp.utils';
import AccountVerification from '../common/AccountVerification';
import {
  SIMPLE_TYPE,
  NORMAL_TYPE,
  FULL_BIG_TYPE,
  FULL_SMALL_TYPE,
  VERTICAL_TYPE,
  VERY_SMALL_TYPE,
  SMALLEST_TYPE,
  PARTICIPATING_TYPE,
  COMPLETED_TYPE,
  FULL_BIG_TYPE_CREATOR,
} from '../../constants/avatarType.constants';
import EventEmitter from '../../utils/events';
import './scss/avatar.scss';
import { useHistory } from 'react-router-dom';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { LuShare } from 'react-icons/lu';
import ShareModalTreehouse from '../views/treehouse/ShareModalTreehouse';
import { CAPTAIN_ROUTE, PAYMENT_ROUTE } from '../../constants/routes.constants';
import { getKidSubscription } from '../../utils/subscription.util';
import { getMoodIcon } from '../../utils/mood.utils';
import './avatar.css';

const Avatar = ({
  creator,
  shareModal,
  setShareModalProps,
  isFullView,
  type,
  ...props
}) => {
  const history = useHistory();
  const { guardian, dependent, setUser, school, partner } = useAuth();
  const [showSpinner, setShowSpinner] = useState(false);
  const accountVerificationRef = useRef();
  const [showShareModal, setShowShareModal] = useState(false);

  const getSelectedUserData = (imageUrl = null) => {
    let userProfileUrl;
    let userBannerUrl;
    let groupName;
    let schoolName;
    let teacherId;
    let favorites;
    let userName;
    let firstName;
    let lastName;
    let taletreeFriend;
    let subscription;
    let isActivated;

    if (dependent) {
      userName = dependent.username;
      groupName = dependent.profile.group.name;
      schoolName = dependent.school_details.school_name;
      teacherId = dependent.profile?.group?.group_captain?.id;
      favorites = dependent.profile.favorites;
      firstName = dependent.first_name;
      lastName = dependent.last_name;
      taletreeFriend = dependent.profile.taletree_friend;
      subscription = dependent.subscriptions;
      isActivated = dependent.profile.is_activated;
      userProfileUrl = imageUrl ? imageUrl : getUserProfileUrl(dependent);
      userBannerUrl = imageUrl ? imageUrl : getUserBannerUrl(dependent);
    } else if (guardian) {
      userProfileUrl = guardianDefaultProfilePicture;
      userName = guardian.first_name;
      firstName = guardian.first_name;
      lastName = guardian.last_name;
    } else if (school) {
      (userProfileUrl = imageUrl
        ? imageUrl
        : school?.user_type === 'school_teacher'
        ? school?.profile?.picture_url
          ? school?.profile?.picture_url
          : partnerDefaultProfilePicture
        : school.picture_url
        ? school.picture_url
        : partnerDefaultProfilePicture),
        (userName =
          school?.user_type === 'school_teacher'
            ? school?.first_name
            : school?.school_name);
    } else if (partner) {
      (userProfileUrl = imageUrl
        ? imageUrl
        : partner.picture_url
        ? partner.picture_url
        : partnerDefaultProfilePicture),
        (userName =
          partner.user_type === 'partner'
            ? partner.name_of_organisation
            : `${partner.first_name} ${partner.last_name}`);
    } else {
      userProfileUrl = props.userProfileUrl;
      userName = props.first_name;
      firstName = props.first_name;
      lastName = props.last_name;
    }

    return {
      userProfileUrl,
      userName,
      groupName,
      schoolName,
      teacherId,
      favorites,
      firstName,
      lastName,
      taletreeFriend,
      subscription,
      isActivated,
    };
  };

  const [selectedUser, setSelectedUser] = useState(getSelectedUserData());

  const getSelectedUser = (data) => {
    const selectedUserData = getSelectedUserData(data.preview);
    setSelectedUser({ ...selectedUserData });
  };

  EventEmitter.subscribe('profile_pic', getSelectedUser);
  EventEmitter.subscribe('partner_profile_pic', getSelectedUser);

  useEffect(() => {
    return () => {
      EventEmitter.unsubscribe('profile_pic', getSelectedUser);
      EventEmitter.unsubscribe('partner_profile_pic', getSelectedUser);
    };
  }, []);

  const onShareModalClick = () => {
    shareModal(true);
    setShareModalProps(props);
  };

  const handleCampClick = (kid) => {
    if (kid.profile?.group?.group_captain?.id) {
      history.push(`${CAPTAIN_ROUTE}/${kid.profile?.group?.group_captain?.id}`);
    }
  };

  const handleTeacherCampClick = (content) => {
    if (content?.teacherId) {
      history.push(`${CAPTAIN_ROUTE}/${content?.teacherId}`);
    }
  };

  const campBadge = (campName) => {
    return isStrNullOrEmpty(campName) ? (
      <span></span>
    ) : (
      <Badge className="group-name" variant="success">
        Camp {campName}
      </Badge>
    );
  };
  const campBadge_all_kids = (campName, schoolName) => {
    return isStrNullOrEmpty(campName) ? (
      <span></span>
    ) : (
      <>
        <Badge className="group-name" variant="success">
          {schoolName ? schoolName : ''}
        </Badge>{' '}
        <Badge className="group-name" variant="danger">
          {campName}
        </Badge>
      </>
    );
  };
  const campBadgeKidsRoom = (content) => {
    return isStrNullOrEmpty(content?.groupName) ? (
      <span></span>
    ) : (
      <>
        <Badge className="group-name" variant="success">
          {content?.schoolName}
        </Badge>{' '}
        <Badge className="group-name" variant="danger">
          {content?.groupName}
        </Badge>
      </>
    );
  };

  const avatarContent = () => {
    let imageClassName;
    let res;
    let content;
    let favorites = [];
    let shareModalProps;

    const makeFavorites = (favorite) => {
      if (favorites.length !== 0) {
        if (!isStrNullOrEmpty(favorite)) {
          favorites.push(' · ');
          favorites.push(favorite);
        }
      } else {
        if (!isStrNullOrEmpty(favorite)) {
          favorites.push(favorite);
        }
      }
    };

    switch (type) {
      case SIMPLE_TYPE:
        imageClassName = 'simple-type';
        res = (
          <div className={imageClassName}>
            <Image
              className="rounded-circle"
              src={selectedUser.userProfileUrl}
            />
            {guardianNameCorrection(selectedUser.userName)}
          </div>
        );
        break;
      case NORMAL_TYPE:
        imageClassName = 'normal-type';
        content = props ? props : selectedUser;
        content.userProfileUrl = isStrNullOrEmpty(content?.userProfileUrl)
          ? getDefaultProfileImage(content?.taletreeFriend)
          : content?.userProfileUrl;
        const specialSize = props.size
          ? {
              wrapper: {
                height: props.size + 'px',
              },
              image: {
                width: props.size + 'px',
                height: props.size + 'px',
                position: 'relative',
                display: 'inline-flex',
              },
            }
          : {
              wrapper: {
                height: '55px',
              },
              image: {
                width: '55px',
                height: '55px',
              },
            };
        res = (
          <div className={imageClassName}>
            {/* style={specialSize.wrapper}> */}
            <div className="avatar-image">
              {content?.activated && (
                <Image className={'verifiedIcon'} src={verfiedIcon} />
              )}
              {/* style={specialSize.image}> */}
              <Image
                className={`rounded-circle dashboard-kid-avatar`}
                style={specialSize.image}
                src={content?.userProfileUrl}
                onClick={() => history.push(`/${content?.userName}`)}
                role="button"
              />
              {!content?.activated && (
                <span
                  className={'unverified-patch'}
                  // onClick={() => accountVerificationRef.current.handleOpen()}
                  onClick={() =>
                    history.push({
                      pathname: `${PAYMENT_ROUTE}`,
                      state: {
                        dependent: JSON.stringify(content),
                        type: 'activate',
                      },
                    })
                  }
                >
                  <Image src={unverifiedPatch} />
                  Unverified
                </span>
              )}
              {/* {!content?.activated &&
                <AccountVerification id={content?.id} firstName={content?.firstName} ref={accountVerificationRef} />
              } */}
            </div>
            <div className="user-info">
              <div className="user-name">
                <span
                  className="add-pointer"
                  onClick={() => history.push(`/${content?.userName}`)}
                >
                  {content?.userName}
                </span>
              </div>
              <div className="full-name">
                {content?.firstName} {content?.lastName}
              </div>
              <div style={{ display: 'flex', gap: '10px' }}>
                {content?.activated && (
                  <div className={`subscription`} id="guardian-step-one">
                    <span className={getKidSubscription(content)}>
                      {getKidSubscription(content)}
                    </span>
                  </div>
                )}
                {content?.isInsights && (
                  <div className={`subscription`}>
                    <span className="current-plan">
                      {content?.profile?.group?.name}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        );
        break;
      case FULL_BIG_TYPE:
        imageClassName = 'full-big-type';
        content = selectedUser;
        content?.favorites.map(makeFavorites);
        shareModalProps = content?.userName;
        const renTooltip = (kid) => (
          <Tooltip id="button-tooltip">
            {content?.isActivated
              ? 'This account is verified because Guardians and Kids have completed KYC so are able to use TaleTree.'
              : 'This account is not verified because Guardians have not completed KYC so this user is unable to interact on TaleTree.'}
          </Tooltip>
        );
        res = (
          <div className={imageClassName}>
            <ShareModalTreehouse
              showShareModal={showShareModal}
              setShowShareModal={setShowShareModal}
              shareModalProps={shareModalProps}
            />
            <Row>
              <div className="avatar-image">
                {props?.todayMood?.length > 0 && (
                  <Image
                    className="mood-button"
                    src={getMoodIcon(props?.todayMood[0]?.mood)}
                  />
                )}
                <Image
                  className="rounded-circle profile-image"
                  src={content?.userProfileUrl}
                />
                {props?.todayMood?.length > 0 && (
                  <span
                    className={`Avatar_Mood_Title borderRadius_100 ${props.todayMood[0]?.mood}-mood`}
                  >
                    I'm {capitalizeFirstLetter(props?.todayMood[0]?.mood)}
                  </span>
                )}

                <Image
                  className="edit-button"
                  onClick={props.handleEditProfile}
                  src={iconEdit}
                />
                <Spinner
                  className={`spinner ${showSpinner ? '' : 'd-none'}`}
                  animation="border"
                  variant="info"
                />
              </div>
              <div className="user-info">
                <div className="name-favorites">
                  <div>{campBadgeKidsRoom(content)}</div>
                  <div className="user-name">
                    {content?.userName}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renTooltip(content)}
                    >
                      {content?.isActivated ? (
                        <Image className="blue_badge" src={verfiedIcon} />
                      ) : (
                        <Image className="blue_badge" src={unVerfiedIcon} />
                      )}
                    </OverlayTrigger>
                    <span
                      onClick={() => setShowShareModal(true)}
                      style={{ marginLeft: '7px', cursor: 'pointer' }}
                    >
                      <LuShare style={{ height: '22px' }} />
                    </span>
                  </div>
                  <div className="favorites">{favorites}</div>
                </div>
              </div>
            </Row>
            <Row className="badge-wrapper">
              <MyBadge
                icon={starIcon}
                count={props.creationCount}
                className="first-badge"
                title="Creations"
              />
              <MyBadge
                icon={flagIcon}
                count={props.challengeCount}
                title="Challenges"
              />
              <MyBadge
                icon={binkyIcon}
                count={props.binkyCount}
                title="Binkies"
              />
            </Row>
          </div>
        );
        break;
      case FULL_BIG_TYPE_CREATOR:
        imageClassName = 'full-big-type';
        content = creator;
        content?.profile?.favorites.map(makeFavorites);
        shareModalProps = content?.username;
        const renderTooltip = (kid) => (
          <Tooltip id="button-tooltip">
            {kid.profile.is_activated
              ? 'This account is verified because Guardians and Kids have completed KYC so are able to use TaleTree.'
              : 'This account is not verified because Guardians have not completed KYC so this user is unable to interact on TaleTree.'}
          </Tooltip>
        );

        res = (
          <div className={imageClassName}>
            <ShareModalTreehouse
              showShareModal={showShareModal}
              setShowShareModal={setShowShareModal}
              shareModalProps={shareModalProps}
            />
            <Row>
              <div className="avatar-image">
                {props?.todayMood?.length > 0 && (
                  <Image
                    className="mood-button"
                    src={getMoodIcon(props?.todayMood[0]?.mood)}
                  />
                )}
                <Image
                  className="rounded-circle profile-image"
                  src={
                    content?.profile?.picture_url
                      ? content?.profile?.picture_url
                      : getUserProfileUrl(content)
                  }
                />
                {props?.todayMood?.length > 0 && (
                  <span
                    className={`Avatar_Mood_Title borderRadius_100 ${props.todayMood[0]?.mood}-mood`}
                  >
                    I'm {capitalizeFirstLetter(props?.todayMood[0]?.mood)}
                  </span>
                )}
                {/* <Image className="edit-button verify-icon" src={content?.profile.is_activated ? verfiedIcon : unVerfiedIcon} /> */}
                <Spinner
                  className={`spinner ${showSpinner ? '' : 'd-none'}`}
                  animation="border"
                  variant="info"
                />
              </div>
              <div className="user-info">
                <div className="name-favorites">
                  <div>
                    {campBadge_all_kids(
                      content?.profile?.group?.name,
                      content?.school_details?.school_name
                    )}
                  </div>
                  <div className="user-name">
                    {content?.username}
                    <OverlayTrigger
                      placement="top"
                      delay={{ show: 250, hide: 400 }}
                      overlay={renderTooltip(content)}
                    >
                      {content?.profile?.is_activated ? (
                        <Image className="blue_badge" src={verfiedIcon} />
                      ) : (
                        <Image className="blue_badge" src={unVerfiedIcon} />
                      )}
                    </OverlayTrigger>
                    <span
                      onClick={() => setShowShareModal(true)}
                      style={{ marginLeft: '7px', cursor: 'pointer' }}
                    >
                      <LuShare style={{ height: '22px' }} />
                    </span>
                  </div>

                  <div className="favorites">{favorites}</div>
                </div>
              </div>
            </Row>
            <Row className="badge-wrapper">
              <MyBadge
                icon={starIcon}
                count={props.creationCount}
                className="first-badge"
                title="Creations"
              />
              <MyBadge
                icon={flagIcon}
                count={props.challengeCount}
                title="Challenges"
              />
              <MyBadge
                icon={binkyIcon}
                count={props.binkyCount}
                title="Binkies"
              />
            </Row>
          </div>
        );
        break;
      case FULL_SMALL_TYPE:
        imageClassName = 'full-small-type';
        content = props ? props : selectedUser;
        content?.favorites.map(makeFavorites);
        content.userProfileUrl = isStrNullOrEmpty(content?.userProfileUrl)
          ? getDefaultProfileImage(content?.taletreeFriend)
          : content?.userProfileUrl;
        res = (
          <div
            className={imageClassName}
            role="button"
            onClick={() => history.push(`/${content?.userName}`)}
          >
            <div className="avatar-image">
              <Image className="rounded-circle" src={content?.userProfileUrl} />
              <span className="verified-badge">
                <Image className={'verifiedIcon'} src={verfiedIcon} />
              </span>
            </div>
            <div className="user-info">
              <div className="user-name">
                <div className="nickname">{content?.userName}</div>
                <div className="groupname">{campBadge(content?.groupName)}</div>
              </div>
              <div className="favorites">{favorites}</div>
              {/* {campBadge(content?.groupName)}
              <br />
              <span className="user-name">{content?.userName}</span>
              <br />
              <span className="favorites">{favorites}</span> */}
            </div>
          </div>
        );
        break;
      case VERY_SMALL_TYPE:
        imageClassName = 'very-small-type';
        content = props ? props : selectedUser;
        content?.favorites.map(makeFavorites);
        res = (
          <div className={imageClassName}>
            <div
              className="avatar-image"
              role="button"
              onClick={() => {
                if (content?.userType === 'dependent')
                  history.push(`/${content?.userName}`);
              }}
            >
              <img className="rounded-circle" src={content?.userProfileUrl} />
              <span className="verified-badge">
                <Image className={'verifiedIcon'} src={verfiedIcon} />
              </span>
            </div>
            <div className="user-info" role="button">
              <div className="name-container">
                <div
                  className="name"
                  onClick={() => {
                    if (content?.userType === 'dependent')
                      history.push(`/${content?.userName}`);
                  }}
                >
                  {content?.userName}{' '}
                  {content?.userType !== 'dependent' && (
                    <button className="blue-admin-button">
                      {content?.userType === 'school_teacher'
                        ? 'Captain'
                        : content?.userType === 'school'
                        ? 'Master'
                        : content?.userType === 'camp master'
                        ? 'Expert'
                        : 'Partner'}
                    </button>
                  )}
                </div>
                <div>{campBadge(content?.groupName)}</div>
              </div>
              <div className="favorites remove-pointer">{favorites}</div>
            </div>
            {!isFullView ? (
              <div
                className="share_modal_act"
                onClick={onShareModalClick}
                role="button"
              >
                <HiDotsHorizontal />
              </div>
            ) : guardian ? (
              <div
                className="share_modal_act"
                onClick={onShareModalClick}
                role="button"
              >
                <HiDotsHorizontal />
              </div>
            ) : (
              ''
            )}
          </div>
        );
        break;
      case VERTICAL_TYPE:
        imageClassName = 'vertical-type';
        content = props ? props : selectedUser;
        content.userProfileUrl = isStrNullOrEmpty(content?.userProfileUrl)
          ? dependentDefaultProfile()
          : content?.userProfileUrl;
        res = (
          <div id="vertical" className={imageClassName}>
            <div className="avatar-image">
              <Image className="rounded-circle" src={content?.userProfileUrl} />
            </div>
            <div className="user-name">{content?.userName}</div>
            <div className="full-name">
              {content?.firstName} {content?.lastName}
            </div>
          </div>
        );
        break;
      case SMALLEST_TYPE:
        imageClassName = 'smallest-type';
        content = props ? props : selectedUser;
        res = (
          <div className={imageClassName}>
            <div className="avatar-image">
              <Image
                className="rounded-circle overlap-item"
                src={content?.userProfileUrl}
              />
              {/* <span className='verified-patch'><BsBellFill/> Unvaryfied</span> */}
            </div>
          </div>
        );
        break;
      case PARTICIPATING_TYPE:
        imageClassName = 'participating-type';
        res = (
          <div
            className={imageClassName}
            role="button"
            onClick={() => history.push(`/${props.user.username}`)}
          >
            <div className="avatar-image">
              <Image
                className="rounded-circle overlap-item"
                src={props.userProfileUrl}
              />
              <span className="verified-badge">
                <Image className={'verifiedIcon'} src={verfiedIcon} />
              </span>
            </div>
            <div className="user-info">
              <div className="username">{props.user.username}</div>
            </div>
          </div>
        );
        break;
      case COMPLETED_TYPE:
        imageClassName = 'completed-type';
        res = (
          <div
            className={imageClassName}
            role="button"
            onClick={() => history.push(`/${props.user.username}`)}
          >
            <div className="avatar-image">
              <Image
                className="rounded-circle overlap-item"
                src={props.userProfileUrl}
              />
            </div>
            <div className="user-info">
              <div className="username">{props.user.username}</div>
              <div className="created-at">
                <ReactTimeAgo date={props.createdAt} timeStyle="twitter" />
              </div>
            </div>
          </div>
        );
        break;
      default:
        res = (
          <div className={imageClassName}>
            <Image
              className="rounded-circle"
              src={selectedUser.userProfileUrl}
            />
            {selectedUser.userName}
          </div>
        );
    }
    return res;
  };

  return <div className="avatar-wrapper">{avatarContent()}</div>;
};

Avatar.defaultProps = {
  type: NORMAL_TYPE,
  userProfileUrl: dependentDefaultProfilePicture,
  userName: '',
  firstName: '',
  lastName: '',
  groupName: '',
  size: 55,
  favorites: [],
};

export default Avatar;